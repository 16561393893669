import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useAccountQuery from 'queries/useAccountQuery';
import Link from '@activebrands/core-web/components/Link';
import overlay from '@activebrands/core-web/libs/overlay';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { resetPassword } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import Form from 'components/Form';
import OverlayContent from 'components/Header/OverlayContent';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
import StateIcon from 'components/icons/StateIcon';
import ConfirmationMessage from './ConfirmationMessage';

const ResetPasswordForm = ({ setForm, forgottenPasswordText, infoText }) => {
    const dispatch = useTypedDispatch();
    const [css] = useStyletron();
    const { forgotPasswordContent, forgotPasswordConfirmationContent } = useAccountQuery();

    const [requestEmail, setRequestEmail] = useState(undefined);

    const [state, setState] = useState({
        email: undefined,
        errors: [],
        isLoading: false,
        isSubmitted: false,
    });

    const { isLoading, isSubmitted } = state;

    const onSubmit = async ({ email }) => {
        setState(prevState => ({ ...prevState, isLoading: true }));

        const response = await dispatch(resetPassword(email));

        if (response.message === 'Success') {
            setRequestEmail(email);
            setState(prevState => ({ ...prevState, email, isLoading: false, isSubmitted: true, errors: [] }));
        } else {
            setState(prevState => ({ ...prevState, errors: [response?.error], isLoading: false }));
        }
    };

    return isSubmitted ? (
        <>
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    padding: '12px',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid var(--color-border-account)',
                })}
            >
                <ThemeButton
                    $style={{ display: 'inline-flex', gap: '8px', padding: 0 }}
                    disabled={isLoading}
                    size="md"
                    theme="link"
                    fontKeys="Primary/16_100_-05"
                    type="submit"
                    onClick={() => setForm('sign-in')}
                >
                    {fm('Back to sign in')}
                </ThemeButton>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        width: '40px',
                        height: '100%',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                    })}
                    onClick={() => overlay.close('unauthenticated')}
                >
                    <CloseIcon size="16px" state="reset" />
                </div>
            </div>
            <ConfirmationMessage
                $style={{ padding: '50% 12px 0 12px' }}
                content={forgotPasswordConfirmationContent}
                userEmail={requestEmail}
                buttonText={fm('Back to sign in')}
                heading={fm('One step left..')}
                onClick={() => setForm('sign-in')}
            />
        </>
    ) : (
        <div>
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    padding: '12px',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid var(--color-border-account)',
                })}
            >
                <ThemeButton
                    $style={{ display: 'inline-flex', gap: '8px', padding: 0 }}
                    disabled={isLoading}
                    size="md"
                    theme="link"
                    fontKeys="Primary/16_100_-05"
                    type="submit"
                    onClick={() => setForm('sign-in')}
                >
                    {fm('Back to sign in')}
                </ThemeButton>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        width: '40px',
                        height: '100%',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                    })}
                    onClick={() => overlay.close('unauthenticated')}
                >
                    <StateIcon size="16px" state="reset" />
                </div>
            </div>
            {forgotPasswordContent && (
                <OverlayContent content={forgotPasswordContent} wrapperStyles={{ padding: '50% 12px 0 12px' }} />
            )}
            <Form
                $style={{
                    padding: '0px 12px',
                }}
                buttonLabel={isLoading ? `${fm('Loading')}...` : fm('Reset password')}
                buttonProps={{
                    disabled: isLoading,
                    fontKeys: 'Primary/16_100_-05',
                    $style: {
                        display: 'flex',
                        justifyContent: 'center',
                        flexBasis: '100%',
                        marginTop: '24px',
                        marginBottom: '16px',
                    },
                }}
                errors={state.errors}
                fields={[
                    {
                        label: fm('Email'),
                        name: 'email',
                        required: true,
                        type: 'email',
                        $style: {
                            flexBasis: '100%',
                        },
                    },
                ]}
                onSubmit={onSubmit}
            />
            {forgottenPasswordText && <RichText data={forgottenPasswordText} tagComponents={{ paragraph: infoText }} />}
        </div>
    );
};

ResetPasswordForm.propTypes = {
    forgottenPasswordText: PropTypes.string,
    infoText: PropTypes.string,
    setForm: PropTypes.func,
};

export default ResetPasswordForm;
