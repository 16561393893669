import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useAccountQuery from 'queries/useAccountQuery';
import useForm from '@activebrands/core-web/hooks/useForm';
import overlay from '@activebrands/core-web/libs/overlay';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { signUp } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import InputErrors from 'components/Form/InputErrors';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
import LoadingIcon from 'components/icons/LoadingIcon';
import StateIcon from 'components/icons/StateIcon';
import Checkbox from 'components/inputs/CheckboxInput';
import Input from 'components/inputs/Input';
import Paragraph from 'components/text/Paragraph';
import ConfirmationMessage from './ConfirmationMessage';

const fields = [
    ['email', fm('Email'), 'email'],
    ['password', fm('Password'), 'password', { minLength: 8 }],
    ['passwordConfirmation', fm('Confirm password'), 'password', { minLength: 8 }],
];

const SignUpFormStepOne = ({ setForm, setUserEmail }) => {
    const dispatch = useTypedDispatch();
    const [css] = useStyletron();

    const { signUpTosLabel, registerConfirmationContent } = useAccountQuery();

    const [{ isLoading, isSubmitted, email, errors }, setState] = useState({
        isLoading: false,
        isSubmitted: false,
        email: undefined,
        errors: [],
    });

    const form = useForm(async field => {
        const { email, password, passwordConfirmation } = field;

        if (password !== passwordConfirmation) {
            form.setErrors(prevErrors => ({
                ...prevErrors,
                passwordConfirmation: ['passwordMismatch'],
            }));

            return;
        }

        setState(prevState => ({ ...prevState, isLoading: true }));

        //TODO: will need this when 2 step registration is secure and available.
        const response = await dispatch(
            signUp({
                email,
                password,
                // isRegistration: true,
                // optOut: false,
            })
        );

        if (response.status === 200) {
            setState(prev => ({ ...prev, isSubmitted: true, email: email, errors: [], isLoading: false }));
            // setForm('sign-up-step-two'); TODO: Put back when sign up step 2 can be done without any security flaw.

            setUserEmail({ email: email });
        } else {
            setState(prev => ({
                ...prev,
                errors: [response],
                isLoading: false,
            }));
        }
    });

    const handleChange = event => {
        form.errors[event.target.name] ? form.validateInput(event) : undefined;
    };

    {
        /*TODO: Move message to step 2 when step 2 can be done without any security flaw.*/
    }
    return isSubmitted ? (
        <>
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    padding: '12px',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid var(--color-border-account)',
                })}
            >
                <ThemeButton
                    $style={{ display: 'inline-flex', gap: '8px', padding: 0 }}
                    disabled={isLoading}
                    size="md"
                    theme="link"
                    fontKeys="Primary/16_100_-05"
                    type="submit"
                    onClick={() => setForm('sign-in')}
                >
                    {fm('Back to sign in')}
                </ThemeButton>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        width: '40px',
                        height: '100%',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                    })}
                    onClick={() => overlay.close('unauthenticated')}
                >
                    <CloseIcon size="16px" state="reset" />
                </div>
            </div>
            <ConfirmationMessage
                $style={{ padding: '50% 12px 0 12px' }}
                content={registerConfirmationContent}
                userEmail={email}
                onClick={() => setForm('sign-in')}
            />
        </>
    ) : (
        <>
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    padding: '12px',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid var(--color-border-account)',
                    marginBottom: '16px',
                })}
            >
                <ThemeButton
                    $style={{ display: 'inline-flex', gap: '8px', padding: 0 }}
                    disabled={isLoading}
                    size="md"
                    theme="link"
                    fontKeys="Primary/16_100_-05"
                    type="submit"
                    onClick={() => setForm('sign-in')}
                >
                    {fm('Back to sign in')}
                </ThemeButton>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        width: '40px',
                        height: '100%',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                    })}
                    onClick={() => overlay.close('unauthenticated')}
                >
                    <StateIcon size="16px" state="reset" />
                </div>
            </div>

            <div
                className={css({
                    padding: '0px 12px',
                })}
            >
                <Paragraph $style={{ marginBottom: '16px' }} fontKeys="Primary/16_100_-05">
                    {fm('Sign up')}
                </Paragraph>
                {/*TODO: Put back when sign up step 2 can be done without any security flaw. */}
                {/* <Paragraph $style={{ marginBottom: '16px' }} fontKeys="Regular-16/100">
                    {fm('Step 1/2')}
                </Paragraph> */}
            </div>
            <form {...form.props} id="sign-up-step-one" className={css({ marginTop: '32px', padding: '0px 12px' })}>
                {fields.map(([name, label, type, rest]) => (
                    <Input
                        required
                        $style={{ marginTop: '24px' }}
                        errors={form.errors[name]}
                        key={name}
                        label={label}
                        maxLength="150"
                        name={name}
                        placeholder={label}
                        type={type}
                        {...rest}
                        onChange={handleChange}
                    />
                ))}
                {errors.length > 0 && <InputErrors errors={errors} />}

                <Checkbox
                    required
                    $style={{ marginTop: '24px', textAlign: 'left' }}
                    errors={form.errors.confirm}
                    label={signUpTosLabel}
                    name="confirm"
                    onChange={form.validateInput}
                />

                <ThemeButton
                    $style={{
                        width: '100%',
                        marginTop: '24px',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    disabled={isLoading}
                    theme="inverted"
                    type="submit"
                >
                    {isLoading ? (
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                            })}
                        >
                            <LoadingIcon />
                        </div>
                    ) : (
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            })}
                        >
                            <Paragraph fontKeys="Miscellaneous/24_100_-2">{fm('Become a member')}</Paragraph>
                            <StateIcon size="16px" state="arrow-right" />
                        </div>
                    )}
                </ThemeButton>
            </form>
        </>
    );
};

SignUpFormStepOne.propTypes = {
    setForm: PropTypes.func,
    setUserEmail: PropTypes.func,
};

export default SignUpFormStepOne;
