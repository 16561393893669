import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import generateRandomString from '@grebban/utils/string/generateRandomString';

const Input = styled('input', ({ $labelPosition, $activeColor, $isLargeSize }) => ({
    width: 0,
    height: 0,
    visibility: 'hidden',

    ':checked + label::before': {
        backgroundColor: $activeColor,
    },

    ':checked + label:after': {
        left: $labelPosition === 'right' ? 'initial' : $isLargeSize ? '60px' : '30px',
        right: $labelPosition === 'right' ? ($isLargeSize ? '-24px' : '-9px') : 'initial',
        transform: 'translateX(-100%) translateY(-50%)',
        backgroundColor: 'var(--color-bg)',
    },
}));

const Label = styled('label', ({ $labelPosition, $inputColor, $isLargeSize }) => ({
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    paddingLeft: $labelPosition === 'right' ? 0 : $isLargeSize ? '68px' : '44px', // 56px (large) or 32px (small) before element + 12px spacing
    paddingRight: $labelPosition === 'right' ? ($isLargeSize ? '68px' : '44px') : 0,

    '::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: $labelPosition === 'right' ? 'initial' : 0,
        right: $labelPosition === 'right' ? 0 : 'initial',
        width: $isLargeSize ? '56px' : '32px', // width 56px if isLargeSize is true
        height: $isLargeSize ? '32px' : '16px', // height 32px if isLargeSize is true
        borderRadius: '16px',
        border: `1px solid ${$inputColor}`,
        transform: 'translateY(-50%)',
        transition: 'all var(--duration-slow) var(--ease)',
    },

    '::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: $labelPosition === 'right' ? 'initial' : $isLargeSize ? '4px' : '2px',
        right: $labelPosition === 'right' ? ($isLargeSize ? '24px' : '18px') : 'initial',
        width: $isLargeSize ? '28px' : '12px', // width 28px if isLargeSize is true
        height: $isLargeSize ? '28px' : '12px', // height 28px if isLargeSize is true
        backgroundColor: $inputColor,
        borderRadius: '90px',
        transform: 'translateY(-50%)',
        transition: 'all var(--duration-slow) var(--ease)',
    },
}));

/**
 *
 * @param {string} [id] - Id for hidden input, auto generated if not set.
 * @param {boolean} [value=false] - Initial value.
 * @param {func} [onChange] - Callback with true or false as argument.
 */
const SwitchInput = ({
    id,
    isLargeSize = true,
    label,
    value,
    defaultValue,
    onChange,
    $style,
    labelStyle,
    labelPosition = 'right',
    name,
    disabled,
    inputColor = 'var(--color-text)',
    activeColor = 'var(--color-bg-inverted)',
}) => {
    const internalId = useMemo(() => id || generateRandomString(7), [id]);
    const [checked, setChecked] = useState(value);
    const [css] = useStyletron();

    const handleChange = event => {
        setChecked(prevChecked => !prevChecked);
        onChange && onChange(event);
    };

    useEffect(() => {
        if (value !== checked) {
            setChecked(value);
        }
    }, [value]);

    return (
        <div className={css({ position: 'relative', ...$style })}>
            <Input
                $activeColor={activeColor}
                checked={checked}
                defaultChecked={defaultValue}
                disabled={disabled}
                id={internalId}
                $isLargeSize={isLargeSize}
                $labelPosition={labelPosition}
                name={name}
                type="checkbox"
                onChange={value !== undefined ? handleChange : undefined}
            />
            <Label
                $inputColor={inputColor}
                $style={labelStyle}
                htmlFor={internalId}
                $isLargeSize={isLargeSize}
                $labelPosition={labelPosition}
            >
                {label}
            </Label>
        </div>
    );
};

SwitchInput.propTypes = {
    $style: PropTypes.object,
    activeColor: PropTypes.string,
    defaultValue: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    inputColor: PropTypes.string,
    isLargeSize: PropTypes.string,
    label: PropTypes.string,
    labelPosition: PropTypes.string,
    labelStyle: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
};

export default SwitchInput;
